<script setup lang="ts">
import { onMounted } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { pluralize } from '/~/utils/format/string'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import {
  useBatchOrder,
  useBatchOrderPayeesTable,
} from '/~/composables/batch-order'
import SummaryPayScheduled from './summary-pay-scheduled.vue'
import SummaryPayToAmount from './summary-pay-to-amount.vue'
import SummaryPayToHeader from './summary-pay-to-header.vue'

const { batchOrder } = useBatchOrder()
const { batchOrderPayeesTable, initBatchOrderPayeesPreview } =
  useBatchOrderPayeesTable()

onMounted(() => {
  initBatchOrderPayeesPreview()
})

function openModal() {
  if (ui.mobile) {
    bottomSheet.show('batch-payment-payee-list')
  } else {
    modal.show('batch-payment-payee-list')
  }
}
</script>

<template>
  <div v-if="batchOrder.number" class="flex shrink-0 flex-col">
    <summary-pay-to-header class="mb-4" />
    <div class="flex shrink-0 gap-x-4">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-eonx-neutral-600 text-white"
      >
        <base-icon :size="32" svg="v2/custom/bank" />
      </div>
      <div class="flex w-full flex-col text-eonx-neutral-600">
        <div class="text-base font-bold text-eonx-neutral-800">
          {{ batchOrder.fileName }}
        </div>
        <div class="flex justify-between">
          <div>Description: {{ batchOrder.reference }}</div>

          <summary-pay-to-amount class="ml-auto self-end" />
        </div>
        <summary-pay-scheduled class="mt-[5px]" />
      </div>
    </div>
    <div
      class="ml-[24px] mt-3 flex items-center border-l px-10 py-1.5 text-eonx-neutral-600"
    >
      <div v-if="batchOrderPayeesTable.pagination.totalItems">
        {{ batchOrderPayeesTable.pagination.totalItems }}
        {{ pluralize(batchOrderPayeesTable.pagination.totalItems, 'payment') }}
      </div>
      <div class="mx-2">|</div>
      <div>
        <base-button look="link" @click="openModal">View payments</base-button>
      </div>
    </div>
  </div>
</template>
